import { Box, Typography } from "@ellevation/backpack";
import reading from "img/domain/ReadingFinal.svg";
import writing from "img/domain/WritingFinal.svg";
import listening from "img/domain/ListeningFinal.svg";
import speaking from "img/domain/SpeakingFinal.svg";

export const Outro = ({ domain, questionCount }) => {
	const getDomainImage = domain => {
		switch (domain) {
			case "Reading":
				return reading;
			case "Writing":
				return writing;
			case "Listening":
				return listening;
			case "Speaking":
				return speaking;
		}
	};

	return (
		<Box
			sx={{
				backgroundColor: "#F9F9F9",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				height: "calc(100vh - 57px)"
			}}>
			<Box
				sx={{
					backgroundColor: "white",
					mt: "56px",
					p: "24px 40px",
					width: "50%",
					maxWidth: "711px"
				}}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						pb: "20px",
						borderBottom: "1px solid #CCCCCC"
					}}>
					<img src={getDomainImage(domain)} />
					<Box sx={{ pl: "16px" }}>
						<Typography variant="h1">{domain}</Typography>
						<Typography sx={{ fontSize: "21px", color: "#666666" }}>
							{questionCount} questions
						</Typography>
					</Box>
				</Box>
				<Typography sx={{ pt: "24px", fontSize: "21px" }}>
					Done! You have shared your answers for the {domain} questions. 
				</Typography>
			</Box>
		</Box>
	);
};
